.page {
  position: relative;
  max-width: 800px;
  width: 60%;
  margin: 0 auto;
  padding-top: $header-height;

  @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
@import "_taxonomy";
